<template>
    <section :class="$style.controlsContainer" :style="theme">
        <div :class="[$style.controlBtn]"
            @click="$emit('update:play', {play: !play})">
        <i :class="[$style.controlBtnIcon, !play ? $style.play : $style.pause]"></i>
        </div>
    </section>
</template>
<script>
export default {
  name: 'AudioControlsPanel',
  props: {
    play: {
      type: Boolean,
      default: false
    },
    theme: {
      type: [Object, CSSStyleSheet],
      default () {
        return {
          color: 'rgba(255, 255, 255, .9)',
          backgroundColor: 'rgba(0, 0, 0, .7)'
        }
      }
    }
  },
  created () {
    process.env.VUE_APP_DEBUG && console.debug('AudioControlsPanel created payload: ', { c: this, s: this.$style })
  }
}
</script>
<style lang="scss" module>
@use '~@/assets/scss/font-awesome' as *;
.controls-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 33%;
    min-width: 30em;
    min-height: 10em;
    max-height: 33%;
}
.control-btn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    aspect-ratio: 1 / 1;
    margin: .6em .9em;
    padding: 1.2em;
    .control-btn-icon {
        @include fa-icon;
        @extend .#{$fa-css-prefix}-4x;
    }
    .play {
        @extend .control-btn-icon;
        &:before {
            @extend .#{$fa-css-prefix}-play;
        }
    }
    .pause {
        @extend .control-btn-icon;
        &:before {
            @extend .#{$fa-css-prefix}-pause;
        }
    }
}
</style>
