<template>
    <canvas ref="visualise" style="width:100%; height:100%"></canvas>
</template>
<script>
import Osciloscope from 'oscilloscope'
export default {
  name: 'Visualizer',
  props: {
    source: {
      type: HTMLAudioElement,
      default () {
        return null
      }
    },
    started: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    started (nVal) {
      if (nVal) {
        this.visualize()
      }
    }
  },
  computed: {
    mediaContext () {
      if (this.source) {
        return new (window.AudioContext || window.webkitAudioContext)()
      }
      return null
    },
    streamSource () {
      if (this.source && this.source.readyState >= 2) {
        try {
          const ctx = this.mediaContext
          if (!ctx) {
            throw new Error('Visualizer.ctx.create error')
          }
          const stream = ctx.createMediaElementSource(this.source)
          if (!stream) {
            throw new Error('Visualiser.mediaSource.create error')
          }
          return stream
        } catch (e) {
          process.env.VUE_APP_DEBUG && console.debug('Visualizer error:', e)
          this.$emit('error', e)
        }
      }
      return null
    }
  },
  methods: {
    visualize () {
      try {
        const source = this.streamSource
        const osc = new Osciloscope(source)
        source.connect(this.mediaContext.destination)
        this.$emit('connected', true)
        const canvas = this.$refs.visualise.getContext('2d')
        canvas.lineWidth = 2
        canvas.strockeStyle = '#f89'

        osc.animate(canvas)
      } catch (e) {
        process.env.VUE_APP_DEBUG && console.debug('Visualizer error:', e)
        this.$emit('error', e)
      }
    }
  }
}
</script>
