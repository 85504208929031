<template>
  <main class="container">
    <h3>{{ title }}</h3>
    <h5 v-if="activeSream && activeSream.title">{{ activeSream.title }}</h5>
    <audio ref="media"
            preload="auto"
            @canplay="ready = true"
            @error="onAudioError"
            @loadeddata="onMediaLoad"
            crossorigin="anonymous">
        <source v-if="activeSream"
            :src="activeSream.listenurl"
            :type="activeSream.server_type" />
    </audio>
    <div v-if="!ready || lastError">
        <span :class="[$style.loader]"></span>
        <dl v-if="!lastError && $refs.media">
            <dt>buffering:</dt>
            <dd>{{$refs.media.buffered}}</dd>
            <dt>Connected:</dt>
            <dd>{{connected}}</dd>
            <dt>Ready state:</dt>
            <dd>{{$refs.media.readyState}}</dd>
        </dl>
        <p v-else>Error: {{lastError}}</p>
    </div>
    <div v-show="ready">
        <audio-controls-panel :play="play" @update:play="$event.play ? onPlay() : onStop()"/>
        <visualizer :source="$refs.media" :started="play" @connected="connected = true" @error="lastError = $event"/>
    </div>
  </main>
</template>
<script>
import getStreamInfo from '../api/GetStreamInfo.js'
import AudioControlsPanel from './AudioControlsPanel.vue'
import Visualizer from './Visualizer.vue'
export default {
  name: 'm-player',
  components: {
    AudioControlsPanel,
    Visualizer
  },
  props: {
    title: {
      type: String,
      default: 'MuteamFM streaming player'
    },
    url: {
      type: String,
      default: 'http://fm.error500.ru:8000'
    },
    mount: {
      type: String,
      default: '/muteam-fm'
    }
  },
  data () {
    const data = {
      streams: [],
      activeSream: null,
      selected: `${this.url}${this.mount}`,
      ready: false,
      connected: false,
      play: false,
      lastError: null
    }
    return data
  },
  methods: {
    getStreamInfo: getStreamInfo.get,
    onAudioError (event) {
      process.env.VUE_APP_DEBUG && console.debug('Player.onAudioError.context: ', event)
      this.ready = false
      this.$emit('error', new Error(''))
    },
    onMediaLoad (event) {
      process.env.VUE_APP_DEBUG && console.debug('Player.onMediaLoad.context', { event, r: this.$refs.media })
      /*
      try {
        this.audio = {
          ...this.audio,
          input: this.audio.ctx.createMediaSource(this.$refs.media.captureStream())
        }
        this.audio.input.connect(this.audio.analizer)
        this.connected = true
        this.$emit('loading:audio', { state: { connected: true, ready: false }, stream: { ...this.audio } })
      } catch (e) {
        process.env.VUE_APP_DEBUG && console.debug('Player.onMediaLoad.err: ', e)
        this.$emit('error', e)
      }
      */
    },
    onPlay () {
      if (!this.ready) {
        return
      }
      this.play = true
      this.$refs.media.play()
    },
    onStop () {
      if ((this.play && this.ready) || this.$refs.media.played) {
        this.$refs.media.pause()
        this.play = false
        this.ready = false
        this.$refs.media.load()
      }
    }
  },
  created () {
    this.$on('error', (e) => { this.lastError = e })
    try {
      this.getStreamInfo(this.selected)
        .then((source) => {
          if (source instanceof Array) {
            this.streams = source
            this.$emit('change:streams', source)
          } else {
            this.activeSream = { ...source, server_type: source.server_type === 'application/ogg' ? 'audio/ogg' : source.server_type }
            this.$emit('update:active-stream', this.activeSream)
          }
        })
    } catch (e) {
      this.$emit('error', e)
    }
  },
  mounted () {
    console.debug('Player mounted:', this)
  }
}
</script>
<style lang="scss" module>
@use '~@/assets/scss/font-awesome' as *;
.container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    min-width: 300em;
    margin: 0;
    padding: 3em;
}
.loader {
    @include fa-icon;
    @extend .#{$fa-css-prefix}-spinner;
    @extend .#{$fa-css-prefix}-spin;
}
</style>
