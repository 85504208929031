import axios from 'axios'
import http from 'http'
import https from 'https'
export const UserAgentProps = {
  keepAlive: true,
  keepAliveMsecs: 70000
}
/**
 * Prepare request config
 * @param {AxiosRequest} config
 * @returns {AxiosRequest}
 */
export const config = (config) => {
  let request = {
    url: '/status',
    method: 'GET',
    /* headers: {
      // Accept: 'application/json',
      // 'X-Requested-With': 'XMLHttpRequest',
      // 'X-Muteam': `MuteamPlayer@${process.env.VUE_APP_VERSION}`
    }, */
    responseType: 'json',
    withCredentials: false,
    httpAgent: new http.Agent({ ...UserAgentProps }),
    httpsAgent: new https.Agent({ ...UserAgentProps })
  }

  if (config) {
    if (request.headers) {
      const { headers } = request
      request = { ...request, ...config, headers: { ...headers, ...config.headers } }
    } else {
      request = { ...request, ...config }
    }
  }
  process.env.VUE_APP_DEBUG && console.debug('GetStreamInfo.config return:', { ...request })
  return request
}
/**
 *
 * @param {String} url
 * @param {AxiosRequest} config
 * @return {Array[IcecastSource]} @see https://icecast.org/docs/icecast-2.4.1/server-stats.html
 */
export const get = async function (url, request) {
  if (!url && !request?.url) {
    throw new Error('Error GetStreamInfo arguments: empty "url"');
  }
  const statusUrl = url ? new URL(url) : new URL(request.url);
  const mountFilter = statusUrl.pathname ?? false;

  statusUrl.pathname = '/status-json.xsl';

  request = config({ ...request, url: statusUrl.toString() });
  process.env.VUE_APP_DEBUG && console.debug('GetStreamInfo.get request config:', { ...request })
  try {
    const { data, status, headers } = await axios(request)
    let sourceInfo
    if (mountFilter && typeof data.icestats.source.find === 'function') {
      sourceInfo = data.icestats.source.find((source) => mountFilter === (new URL(source.listenurl)).pathname)
    }
    process.env.VUE_APP_DEBUG && console.debug('GetStreamInfo.get response data:', { ...data, headers, status })
    return sourceInfo || data.icestats.source
  } catch (e) {
    process.env.VUE_APP_DEBUG && console.debug('GetStreamInfo.get request error:', { err: { ...e } })
    if (e.isAxiosError) {
      throw new Error('GetStreamInfo.get network error: Network error')
    }
    if (e instanceof SyntaxError) {
      throw new Error(`GetStreamInfo.get api error: Incorrect JSON response - ${e.message}`)
    }
    throw e
  }
}
export default {
  config,
  get
}
